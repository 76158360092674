.panel{
    position: fixed;
    padding-top: 30px;
    padding-bottom: 50px;
    top: 60px;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-shrink: 0;
    background-color: #636363;
    width:  250px;
    height: 100vh;
    border-right:  2px solid rgba(133, 133, 133, 0.1);
    z-index: 11;
    overflow: scroll;
    transform: translate3d(0, 0, 0);
}
.panelClosed{
    position: fixed;
    padding-top: 30px;
    top: 60px;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-shrink: 0;
    background-color: #636363;
    width:  55px;
    height: 100vh;
    border-right:  2px solid rgba(133, 133, 133, 0.1);
    z-index: 11;
    overflow: "hidden";
    transform: translate3d(0, 0, 0);
}
.panelClosedInsmall{
    position: fixed;
    padding-top: 40px;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-shrink: 0;
    background-color: #636363;
    width:  0;
    height: 100vh;
    border-right:  2px solid rgba(133, 133, 133, 0.1);
    z-index: 11;
    overflow: "scroll";
    transform: translate3d(0, 0, 0);
}